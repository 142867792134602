body .date-range-modal{
    max-width: max-content;

    .react-datepicker__month-container{
        margin: 0 25px;
    }
}

body .date-range-modal .react-datepicker {
   display: flex;
}
.modal.fade.show {
    background: #2f669c66
}

.date-modal{

    .react-datepicker{
        font-family: inherit;
        border-radius: 0;
        border: none;
        width: 100%;
    }

    .react-datepicker__header{
        background-color: white;
    }

    .react-datepicker__current-month{
        font-weight: normal;
        color:#002D66;
        font-size: large;
        margin-top: 15px;
        padding-bottom: 40px;
       
    }

    .date_modal_dropdown{
        font-weight: normal;
        color:#002D66;
        font-size: large;
        margin-left: 30px;
        padding-right:5px ;
        border: none;
        background-color:transparent;
        .react-datepicker__year-read-view--down-arrow{
            top: 8px;
            left: 5px;
        }
    }
    select:disabled{
        color:#d3d3d3;
    }
    .react-datepicker__month-dropdown-container{
        font-weight: normal;
        color:#002D66;
        font-size: large;
        .react-datepicker__month-read-view--down-arrow{
            top: 8px;
        }
    }
    
    .react-datepicker__navigation{
        top:27px;
    }

    .react-datepicker__navigation--next{
        right: 100px;
    }

    .react-datepicker__navigation--previous{
        left: 100px;
    }

    .react-datepicker__month-container{
        float: none;
        width: 100%;
    }
    
    .react-datepicker__day, .react-datepicker__day-name{
        width: 3.8rem;
        line-height: 3.8rem;
        font-size: 1rem;
        margin : 0.2rem 0px;
        border-radius: 0;
    }

    .react-datepicker__day--selected{
        border-radius: 10px;
    }

    .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range {
        color: inherit;
        background-color: #d4e5fa;
    }

    .react-datepicker__day--range-end, .react-datepicker__day--range-start{
        background-color: #3873AE;
        color: white;
        border-radius: 20px;
    }

    .react-datepicker__day--range-end{
        border-radius: 0px 20px 20px 0px;
    }

    .react-datepicker__day--range-start{
        border-radius: 20px 0px 0px 20px;
    }
    .react-datepicker__day-name{
        line-height: 2rem;
    }
}