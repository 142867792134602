.custom-modal-style {
    width: 1655px;
    height: 860px;
    }

    .modal-content{
        width: 1600;
    }

    .innerRealtimeModal{
        min-width: 800px;
  width: 50%;
  height: 50%;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E4E4E4;
  border-radius: 6px;
  padding: 16px;
  margin: auto;
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
    }

    .topContainer{

    }

    .centerContainer{
        height:50%;
    }

    .header{
        text-align: left;
        font-size: 24px;
        color: #002D66;
        padding: 4px 8px; 
    }

    .patientInfo{
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #EBEBEB 0% 0% no-repeat padding-box;
        padding: 0 8px;
    }
    .PatientItemContainer{
        display: flex;
  align-items: center;
  margin-right: 32px;
    }
    .patientItemHeader{
        text-align: left;
        color: #060606;
        margin-right: 4px; 
    }
    .patientItemData{
        text-align: left;
        color: #3FA842;
    }

    .modal-dialog.modal-dialog-bottom .modal-content {
        position: absolute;
        left: 1%;
        bottom: 1%;
    }
    
    .modal.fade.show:has(.modal-overlay-none) {
        background: transparent;
    }
    
    .modal-dialog.modal-dialog-bottom {
        height: 100%;
        margin: 0;
    }




.realtime-content{
    .status-realtime-msg{
        font-size: 20px;
        font-weight: bold;
        white-space: break-spaces;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        flex-direction: column;
        
        span{
            z-index: 2;
        }

    }

    .data-section{
        height: 90%;
        border: 1px solid #fff;
        display: block;
        padding-top: 30px;
    }

    .measurement-section{
        height: 100%;
        position: relative;
    }

    .ecg-wrap{
    }

    .ecg-section{
        position: absolute;
        top: 0;
        display: block;
        width: 80%;
        height: 100%;
        left: 20%;
    }

    .wrap-report-component{

        .wrap-header{
            position: relative;
            margin-bottom: 2%;
        }
    }

    .rt-x-axis-content{
        flex:1;
        display: flex;
        overflow-x: hidden;
        width: 80%;
        margin-left: 20%;
        font-size: 14px;


        .sec-text{
            color:#bebebe;
            flex-basis: 18%;
        }

        .rt-x-axis{
            display: flex;
            justify-content: space-between;
            width: 73%;
        }
    }
}
