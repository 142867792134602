$alertColor: #EF3054;

.pdf-icon{
    position: relative;
    margin: 0 1%;
    
    img{
        width: 1.3rem;
    }

    .pdf-alert-sign{
        position: absolute;
        font-size: 10px;
        color: $alertColor;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: solid 2px $alertColor;
        bottom: -10px;
        right: -9px;
        align-items: center;
        justify-content: center;
        background-color: white;
        display: flex;
        font-weight: bold;
    }
}