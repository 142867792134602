.activate-content {
    margin-top: -120px;

    .activate-text p {
        white-space: pre-wrap;
        font-size: 1.2rem;

    }

    .resend-action {
        color: red;
        cursor: pointer;
        color: #5e72e4;
    }

    .resend-action:hover {
        color: #233dd2;
    }

    .resend-action:active {
        color: #999;
    }

    .activate-btns {
        display: flex;
        justify-content: flex-end;
    }
}

.credentials-content {
    .label-wrap {
        display: flex;
        align-items: center;
    }

    .label-text {
        font-size: 28px;
        
        &.register {
            font-size: 15px;
        }
    }

    .show-password {
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 2%;
    }
}