.size-3{
    font-size: 1.5rem
}

.size-2{
    font-size: 1.2rem
}

#patient-request .content-requests{
    min-height: 99vh;
}

#patient-request table{
    width: 70%;
}

#patient-request img{
    cursor: pointer;
}

#patient-request thead tr, #patient-request .brows-pages{
    background-color: #EFF0F1;
    color: #053F7F;
    border-bottom: none;
}

#patient-request tr{
    height: 3rem;
    color: black;
    font-size: 1.3rem;
    border-bottom: solid 2px #dbdddf;;
}

#patient-request thead span{
    display: flex;
    justify-content: space-between;
}

#patient-request td.table-middle{
    border-left: solid 1px #eff0f1;
    border-right: solid 1px #eff0f1;
    width: 30%;
}

#patient-request td.table-right{
    width: 30%;
}

#patient-request td{
    padding: 0 2%;
}

.req-btns .btn{
    color: black;
    border-radius: 30px;
    font-size: 1.3rem;
    padding: 0% 4%;
    font-weight: 400;
}

.req-btns .btn-outline-danger{
    border-color: rgb(167, 4, 4);
}
.req-btns .btn-outline-success{
    border-color: rgb(4, 167, 56);
}

.brows-pages{
    align-items: center;
    height: 3rem;
    font-size: 1.3rem;
    width: 70%;
    display: flex;
    justify-content: flex-end;
    padding-right: 2%;
}
.brows-pages p{
    margin: 0;
    font-size: 1.3rem;
    padding: 1%;
}

.disabled-arrow{
    opacity: 0.5;
    pointer-events: none;
}

.sleep-section{
    display: flex;
    flex-direction: row;

}
.sleep-value{
    margin-top: 5%;
    line-height: initial;
    font-size: 30px;
}
.sleep-unit{
    font-size: 12px;
    margin-top: 8%;
    margin-left: 2%;
}
.sleep{
    margin-left: 10%;
    margin-top: 2%;
    margin-right: 4%;
}