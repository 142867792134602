#delete-account .title{
    width:812px;
    height: 30px; 
    margin-top:50px;
    margin-left:51px;
    color:#053F7F;
    font-size:20px; 
}

#delete-account .line{
    width:50%;
    height:20px;
    border-top:1px solid #00000029;
    margin-top:20px; 
    margin-left:51px;
}

#delete-account .description{
    margin-left: 51px;
    margin-top: 30px;
    font-size: 20px;
}

#delete-account .worning{
    margin-left: 51px;
    color: #FFAA00;
    font-size: 25px;
    font-weight: bold;
    margin-top: 41px;

}

#delete-account .after-delete{
    margin-left: 51px;
    font-size: 20px;
}
#delete-account .input-title{
    margin-left: 51px;
    margin-top: 41px;
    color: black;
    font-weight: bold;
    font-family: 'Roboto';
    font-size: 20px;
}
#delete-account .input-container{
    width: 70%;
    border-radius: 13px;
    border: 1px solid #707070;
    height: 150px;
    margin-left: 51px;
    margin-top: 20px;
}
#delete-account input {
    border: 1px solid #707070;;
    margin-left: 51px;
    border-radius: 13px;
    font-size: 20px;
    width: 70%;
    height: 120px;
    text-indent: 15px;
    margin-top: 20px;
    text-align: left;
}

#delete-account input::placeholder{
    position: absolute;
    top: 15px;
    
}
#delete-account .support{
    margin-left: 51px;
    margin-top: 20px;
    font-size: 20px;
}

#delete-account .send-request{
    width: 200px;
    height: 40px;
    border-radius: 20px;
    background-color: #3873AE;
    color: #ffffff;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 56px;
    margin-left: 57%;
    margin-top: 10px;
    padding-top: 8px;
    cursor: pointer;
}

#delete-account .long-line{
    width: 70%;
}