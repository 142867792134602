

.wrap-archive{
    padding: 3%;


    .filter-input {
        min-width: max-content;
        border-bottom: solid 1px gray;
        cursor: pointer;
    }

    .toggle-date-picker {
        display: flex;
        align-items: center;

        & > * {
            margin: 0 0.5%;
        }
        
        .date-toggle-title {
            font-size: 1.5rem;
        }
    }
    
    .archive-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #EFEFEF;
        padding: 0.5rem 1rem;
        color: #053f7f;
        font-size: larger;
        margin-top: 4%;

        .archive-header-left {
            display: flex;
            flex-basis: 100%;
        }
    }

    .wrap-pagination{
        min-width: 13rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & > div {
            margin: 0 5%;
        }
    }

    .report-link{
        align-items: center;
        background-color:white;
        padding: 1rem;
        display: flex;
        border-bottom:1px #a3a3a3 solid;
        color:#053f7f ;
        a, .report-link-url {
          cursor: pointer !important;
          color:#053f7f ;
        }
        a:hover, .report-link-url:hover{
          color:#0079FF ;
        }
    }
      
    .wrap-report-detections{
        padding-inline-start: 3%;
    }

    .no-reports-content{
        margin: 2% 1% 0;
        font-size: 1.5rem;
        align-items: center;
        padding: 1rem;
        display: flex;
        border-bottom: 1px #a3a3a3 solid;
        border-top: 1px #a3a3a3 solid;
        color:#053f7f ;
    }
    
}