.measure-box-container {
    width: 157px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 3px 3px 6px #00000029
}

.realtime-box{
    margin-top: 5%;
    color: black;
    font-family: Roboto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box-value{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.realtime-line{
    margin-top: 10%;
    width: 130px;
    border: 1px solid #003F7B;
    opacity: 0.4;
}

.report-box-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.report-container{
    display: flex;
    flex-direction: row;
    margin-left: 1%;
    margin-top: 10%;
    cursor: pointer;
}

.measuremnt-report{
    font-size: 10px;
    padding-left: 5%;
    font-weight: 600;
}

.realtime-last-line{
    margin-top: 10%;
    width: 130px;
    border: 1px solid #003F7B;
    opacity: 0.4;
    margin-left: 10%;
}