$alertColor: #EF3054;
$ppgAlertColor: #A70322;

.alerts-modal{

    img.status{
        width: 20px;
        padding: 3px;
        background-color: #ffffff;
        height: 20px;
        border-radius: 50%;
        border: solid 1px #023f7f;
    }

    .alert-type{
        flex-basis: 30%;
    }

    .alert-date{
        flex-basis: 25%;
    }

    .alert-value{
        flex-basis: 35%;
        display: flex;
        justify-content: flex-end;
    }
}

//detections alerts style
.alerts-modal{
    .detection-alerts{    
        justify-content: flex-end;
    }
}
.detection-alerts{
    display: flex;
    width: 100%;
    margin-inline-end: 2%;
    justify-content: center;

    .detection-alert{
        position: relative;
        margin-inline-start: 18px;
        // margin-inline-start: 10%;

        .alert-hover{
            position: absolute;
            padding: 30%;
            background-color: white;
            box-shadow: 2px 2px 8px;
            border-radius: 7px;
            width: max-content !important;
            transform: translateY(-110%) translateX(-40%);
            display: none;
        }

        .alert-tag{
            border: solid 2px $alertColor;
            border-radius: 30px;
            padding: 10% 20%;
            color: $alertColor;
        }.ppg{
           border-color: $ppgAlertColor; 
           color: $ppgAlertColor;
        }
        &:hover {
            .alert-hover {
                display: block;
            }
        }
    }
}