.wrap-radio-input{
    .form-check-label{
        font-weight: 200;
        color: black;
    }

    .active{
        .form-check-label{
            font-weight: bold;
            color: #3873AE;
        }
        .form-check-input{
            accent-color: #707070;
        }
    }

    
}