.inline-down{
    position: relative;
    top: 5px;
}

.daily-report-static-container{

    .recharts-legend-wrapper {
        font-size: 1.2rem;
    }

    .personal-comments{
        position: relative;
        top: 0;
    }
}


.daily-report-header{
    .report-title{
        display: inline;
        color: #002D66;
        font-size: 1.8rem;

        @media print {
            font-size: initial
        }
    }

    p, span, div{
        font-size: 1.25rem;

        @media print {
            font-size: initial
        }
    }

    
}


.personal-info-box{
    color: black;

    .personal-info-header{
        color: black;
        font-size: 1.7rem;
        padding: '0.85rem 1.5rem';

        @media print {
            font-size: 1.5rem
        }
    }

    .text-info-row{
        font-size: 1.5rem;
        
        @media print {
            font-size: 1.25rem;
            padding-bottom: 1%;
        }

        .text-info-val{
            color: rgb(68, 155, 35);
        }
    }

    .medical-info-row{
        font-size: 1.25rem;
        padding-bottom: 0.4rem;

        @media print {
            font-size: 1rem;
            padding-bottom: 3%;
        }
    }
}