$switchBlue: #023F7F;
$switchGrey: #989898;

.toggle-switch {
    width: 35px;
    height: 15px;
    background-color: rgba($switchGrey, .5);
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    
    &.active{
      background-color: rgba($switchBlue, .5);
    }
  }
  
  .switch-circle {
     position: absolute;
     top:-2px; 
     left: 0px; 
     width:20px; 
     height:20px; 
     background-color:$switchGrey; 
     border-radius:50%; 
     z-index: 1;
  }
  
  .active .switch-circle {
      transform : translateX(15px);
      background-color:$switchBlue; 
  }
  