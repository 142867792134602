/* HeartLoader.css */
.wrap-heart-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  top: 0;
  left: 0;
}

.full-screen{
  position: fixed;
  top: 0;
  left: 0;
  width: 99vw;
}

.heart-path {
  animation: draw 3s linear infinite; /* Duration and infinite looping */
}

@keyframes draw {
  0% {
    stroke-dashoffset: 282.82; /* Start with the path completely hidden */
  }
  50% {
    stroke-dashoffset: 0; /* Fully drawn */
  }
  100% {
    stroke-dashoffset: 282.82; /* End back at hidden */
  }
}