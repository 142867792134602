.empty-report-modal-bg{
    background-color: initial;
}
.empty-report-modal{
    font-size: 25px;
    max-width: 605px;
    .modal-content{
        box-shadow: 3px 3px 4px #00000029;
    }
    .modal-header{
        padding: 3%;
    }
    .modal-body{
        width: 90%;
        margin: auto;
        border: solid 1px #003f7b;
        border-width: 1px 0;
        padding: 10% 5%;
        color: black;
    }
    .modal-footer{
        a{
            background-color: #3873ae;
            color: white;
            border-radius: 40px;
        }
    }
}