$blue: #023F7F;

.arrythmia-filter{
    position: relative;
    margin-inline-start: 5%;
    width: 25%;

    .wrap-filter-input{
        display: flex;

        .filter-input {
            min-width: 185px;
            display: flex;
            align-items: flex-end;
            font-size: 1rem;
        }
    }


    .wrap-ar-tags{
        top: -0.5rem;
        left: -0.5rem;
        width: 300%;
        position: absolute;
        z-index: 1;
        box-shadow: 3px 3px 6px #00000029;
        background-color: #fff;
        padding: 5%;

        hr{
            flex-basis: 100%;
            margin: 1% 0;
            border-bottom: solid 1px $blue;
        }

        .ar-tags{
            display: flex;
            flex-wrap: wrap;
            .ar-tag {
                padding: 0 1%;
                margin: 1% 0.6%;
                min-width: 13%;
                background-color: #EFEFEF;
                border-radius: 8px;
                color: $blue;
                text-align: center;
                cursor: pointer;

                .tag-hover{
                    position: absolute;
                    padding: 0 2%;
                    background-color: white;
                    box-shadow: 2px 2px 8px;
                    border-radius: 7px;
                    width: max-content !important;
                    transform: translateY(-110%) translateX(-40%);
                    display: none;
                    color: #000;
                }
                &:hover {
                    .tag-hover {
                        display: block;
                    }
                }
            }

            .ar-divider {
                display: flex;
                border-left: solid 1px gray;
                color: transparent;
                height: max-content;
                align-self: center;
                width: 5px;
                margin-inline-start: 5px;
            }

            .selected {
                color: white;
                background-color: $blue;
            }
        }
    }

}