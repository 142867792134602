.patient-status-row{
    display: flex;
    align-items: center;
    
    .patient-status-col{
        padding: 16px 0;//this is padding for all the row
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline-end: 1%;
        flex-basis: 33%;
        min-width: 33%;
        background-color: #F6F6F6;

        &.alert-status{
            padding: 21px 0;//this is padding for all the row
        }

        .wrap-status-icon {
            position: relative;
        
            .offwrist-time {
                position: absolute;
                width: 100%;
                text-align: center;
                font-size: 0.5rem;
                font-weight: bold;
                color: #1D3378;
                margin-top: -10%;
            }
        }
    }
    
}

.patients-table .status-icon{
    width: 34px;
    height: 34px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.alert-icon{
        border-radius: 50%;
        border: solid 2px #023f7f;
        width: 24px;
        height: 24px;
        padding: 3px;
        margin: auto;
    }

    &.hide{
        visibility: hidden;
    }
}

.cards-view td .status-icon {
    position: absolute;
    border: none;
    top: -17px;
    right: 22px;
    padding: 5px;
    box-shadow: 0px 3px 6px #00000029;
}

.patients-table .status-icon.socket-close{
    border-color: #747474;
}