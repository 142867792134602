$blue: #023F7F;
$inActive: #989898;
$tableBorderColor: #848484;


.wrap-reminders-page {
    min-height: 60vh;
    margin: 1.5%;
    // background-color: ;
    padding: 2% 15% 2% 2%;

    span.page-title {
        span {
            color: #000;
            font-weight: 200;
            display: block;
        }
    }

    .reminders-actions {
        margin-top: 1%;

        .btn {
            border-radius: 10px;
            background: $blue;
            color: white;
            font-weight: 400;
            font-size: 1.5rem;
            padding: 4px 15px;
        }

        .delete-medicine {
            background: #EBEBEB;
            color: $blue;
            padding: 4px 30px;
        }
    }

    .reminders-table {
        margin-top: 2%;

        &.measurements {
            tr .td-reminder {
                position: initial;
                width: initial;
                text-align: center;
            }
        }
        th {
            font-size: 1.5rem;
            font-weight: 400;
            border: none;
            text-transform: initial;
            color: $blue;
            vertical-align: middle;
            border-bottom: solid 1px $tableBorderColor;
        }

        tr {
            td {
                vertical-align: middle;
                color: #010101;
                font-weight: 200;
                font-size: 1.5rem;
                border-bottom-color: rgba($tableBorderColor, 0.2);
            }

            .td-reminder {
                position: relative;
                width: 10%;
                text-align: center;
            }

            .td-edit {
                width: 10%;
                text-align: center;
            }

            &.last-row{
                td{
                    border-bottom-color: $tableBorderColor;
                }
            }
        }

        .reminder-clock:hover {
            .info-reminders {
                display: block;
            }
        }

        .info-reminders {
            text-align: start;
            display: none;
            position: absolute;
            color: black;
            margin-top: -32px;
            z-index: 2;
            background-color: white;
            right: 70%;
            box-shadow: 0px 5px 10px #00000029;
            font-size: 1rem;
            font-weight: 200;
            padding: 0.5% 1%;
            border-radius: 10px;
            padding: 7%;

            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 0;
                z-index: 1;
                border-style: solid;
                border-color: #fff transparent;
                border-width: 10px 10px 0;
                bottom: 80%;
                right: -5px;
                transform: rotate(40deg);
                background-color: white;
            }

            .reminders-title {
                color: $blue;
                font-weight: 400;
                margin: 0;
            }

            .reminder-row {
                font-size: 1rem;
                margin-top: 2%;
                text-align: start;
                display: flex;
                align-items: center;

                .time {
                    margin-left: 2%;
                }

                img {
                    width: 20px;
                    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(352deg) brightness(96%) contrast(102%);
                }
            }
        }

        .empty-reminders-section {
            text-align: center;
            vertical-align: middle;
            height: 400px;
            color: black;

            img {
                width: 50px;
            }

            p {
                font-size: 1.5rem;
            }
        }

        thead .custom-checkbox img {
            display: block;
        }

        .custom-checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            border-radius: 3px;
            border: solid 2px $blue;

            img {
                width: 12px;
                display: none;
            }
        }

        .selected {
            background-color: #F2F6FA;
            .custom-checkbox {
                img {
                    display: block;
                }
            }
        }
    }
}

.wrap-reminder-form {
    padding: 3%;
    font-size: 1.5rem;
    color: black;

    p.reminder-form-title {
        border-bottom: solid 1px $blue;
        color: $blue;
        font-size: 1.5rem;
        font-weight: 400;

        small {
            padding-left: 2%;
            font-size: 1rem;
            font-weight: lighter;
        }
    }

    .reminders-form {
        max-height: 500px;
        overflow-y: auto;

        .form-group {
            position: relative;

            .input-icon {
                position: absolute;
                top: 47px;
                right: 0;
                padding-right: 2%;

                img {
                    filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(352deg) brightness(96%) contrast(102%);
                    width: 16px;
                }
            }
        }

        .form-control {
            padding-top: 0%;
            padding-bottom: 0%;
            font-size: 1.5rem;
            color: #808080;
            position: relative;


            &:focus {
                color: #000;
                border-color: #2684FF;
            }
        }

        ul.api-options {
            box-shadow: 0px 2px 10px #00000029;
            max-height: 400px;
            overflow-y: auto;
            list-style: none;
            padding: 5px;
            position: relative;
            z-index: 2;
            margin: 0 0.5%;

            li {
                border-bottom: solid 1px black;
                font-weight: 400;
                padding: 5px;
                cursor: pointer;
            }

            :hover {
                background-color: #F2F6FA;
            }
        }

        .check-every-radio div {
            background-color: #707070 !important;
            padding: 6px !important;
            border-radius: 50% !important;
            cursor: pointer !important;
        }

        .reminder-day {
            color: $blue;
            padding: 1% 3%;
            cursor: pointer;
        }

        .selected {
            color: white;
            background-color: $blue;
        }

        .add-reminder {
            color: #5185B8;
            cursor: pointer;
        }

        .reminder.alarm-off {
            .wrap-every-radio {
                color: $inActive;
            }
            .reminder-days {
                .reminder-day {
                    color: $inActive;
                }
                .selected {
                    background-color: $inActive;
                    color: #fff;
                }

            }
        }

        .reminder {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            border-bottom: solid 1px $blue;
            margin: 4% 0;

            input {
                position: relative;
                flex-direction: row-reverse;
                display: flex;
                justify-content: space-between;
                width: 50%;
                border: solid 1px #cad1d7;
                border-radius: 8px;
            }
            /* Wrapper around the hour, minute, second, and am/pm fields as well as 
            the up and down buttons and the 'X' button */
            ::-webkit-datetime-edit-fields-wrapper {
                
            }
            /* The space between the fields - between hour and minute, the minute and 
            second, second and am/pm */
            ::-webkit-datetime-edit-text {
                padding: 0 5%;
                position: absolute;
                top: 0;
                left: 24%;
            }

            // time icon
            ::-webkit-calendar-picker-indicator {
                position: absolute;
                top: 10%;
                right: 3%;
             }


            /* AM/PM */
            ::-webkit-datetime-edit-ampm-field {
                display: none;
            }

            /* Hour */
            ::-webkit-datetime-edit-hour-field {
                position: absolute;
                top: 0;
                left: 10%;
            }

            ::-webkit-datetime-edit-hour-field:focus {
                background-color: #023F7F;
                border-radius: 4px;
                color: #fff;
            }

            /* Minute */
            ::-webkit-datetime-edit-minute-field {
                position: absolute;
                top: 0;
                left: 35%;
            }

            ::-webkit-datetime-edit-minute-field:focus {
                background-color: #023F7F;
                border-radius: 4px;
                color: #fff;
            }

            span.reminder-day {
                border-radius: 8px;
                padding: 0 10px;
            }

            .reminder-days {
                margin: 3% 0;
                flex-basis: 100%;
                justify-content: space-between;
                display: flex;
            }

        }



    }

    button.btn {
        border: solid 2px #3873AE;
        border-radius: 20px;
        padding: 1.5% 4%;
        font-weight: 400;
        color: #3E3E3E;
    }

    button.btn.save-btn {
        background-color: #3873AE;
        color: white;
    }
}

//noticed that the flag is used in patient list too
.row span.info-flag{
    width: 16px !important;
}
.info-flag {
    display: inline-flex;
    font-size: 12px;
    border: solid 1px $blue;
    color: $blue;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;

    &:hover {
        .info-text {
            display: block;
        }
    }
}

span.info-text {
    display: none;
    text-align: start;
    position: absolute;
    color: black;
    margin-top: -110px;
    box-shadow: 0px 5px 10px #00000029;
    font-size: 1rem;
    font-weight: 200;
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    width: max-content;

    @media screen and (min-width: 1600px) {
        margin-top: -120px;
    }
}

.info-text::after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 4;
    border-style: solid;
    border-color: #fff transparent;
    border-width: 10px 10px 0;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
}
