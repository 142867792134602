.cards-view{
    background-color: #F2F6FA;
    margin-left: 20px;
}

.cards-view .card-none{
    display: none;
}

.cards-view tbody {
    display: flex;
    flex-wrap: wrap;
    width: 70vw;
}

.cards-view tr.doctor-table-row {
    position: relative;
    flex-basis: 23%;
    display: flex;
    flex-direction: column;
    margin-right: 2%;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 5px;
    margin-top: 5%;
}

.cards-view table.patients-table td {
    padding-top: 1%;
    padding-bottom: 1%;
}

.cards-view table td{
  border: none;
}
.cards-view .left-text{
    margin-left: 20;
}
.cards-view .id-text{
    font-size: 17px;
    text-align: left;
}
.cards-view .id-text.name{
    font-size: 20px;
    margin-left: 20px;
}

.cards-view tbody{
    width: 100%;
}

.cards-view .line{
  border: 1px solid #EAEAEA;
  margin-top: 5px;
}
.cards-view .ppg-detection{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 20px;
}

.cards-view .measure-value{
    margin-left: 20px;
}

.cards-view .ppg{
    margin-left: 10px;
}

.cards-view .name-container{
    display:flex;
    flex-direction:row;
}

.cards-view .circle{
    padding-top: 5px;
}

.cards-view .cards-line  {
    width: 100%;
    border: 1px solid #b2b1b1;
    margin-top: 5%;
}

.cards-view .pin{
    position: absolute;
    top: 3%;
    left: 13px;
}





.list-view .list-none{
    display: none;
}

.list-view .left-text{
    margin: auto;
}

.id-text{
    text-align: center;
    font-size: 20px;
    color: black;
    font-weight: 300;
}
.id-text.name{
    font-weight: normal;
    color: #1D3378;
}

.list-view td {
    padding: 0 16px !important;
}

.list-view td.status-td{
    padding-inline-start: 0 !important;
    width: 8%;
}

.list-view td div img {
    display: none;
}

.cards-view .detection-alerts-dashboard{
    align-items: center;
    display: flex;

}

.list-view .detection-alerts-dashboard .detection-alerts{
    margin-inline-start: -18px;
}

.list-view .hide-ppg-detection{
    display: none;
}

.patient-list-label{
    font-size: 1.25rem;
}

.list-view .circle{
    padding: 5px !important;
}

.list-view .cards-line{
    display: none;
}

.hamburger-container{
    display:flex;
    justify-content:center;
    flex-direction:row;
    align-items:center;
    padding-right:3%;
}

.hamburger-container .first-img{
    cursor:pointer;
    margin-right: 10px;
}

.hamburger-container .line{
    height:30px;
    border:1px solid #093F7F;
    margin-right:10px;
}

.patients-table{
    min-height: 210px;/* for enough place for the sort menu */
}

.patients-table .box{
    text-align: center;
}

.patients-table thead th.box{
    position: relative;
    color: #023F7F;
    font-size: 1.25rem;
    font-family: Roboto;
    text-transform: inherit;
    font-weight: 400;
    background-color: transparent;
    border-top: none;
}
.patients-table thead th.box.detection_alerts {
    position: initial;
}

.patients-table .box.phonenumber, .box.idnumber, .box.name, .box.status{
    text-align: start;
}

.info-detection span{
    vertical-align: middle;
}
.info-detection span.detection-legend{
    display: inline-block;
    height: 16px;
    width: 16px !important;
    background-color: #FF3359;
    margin-inline-end: 5%;
    border-radius: 50%;
}
.info-detection.info-ppg span.detection-legend{
    background-color: #A70322;
}

img.sort-toggle.open {
    transform: rotate(180deg);
}

.sort-nav {
    min-width: 200px;
    position: absolute;
    background-color: white;
    left: 0;
    top: 100%;
    box-shadow: 2px 3px 8px #A8A8A7;
    font-size: 1rem;
    color: black;
    font-weight: 200;
    z-index: 1;
}

.sort-nav-title {
    color: #023F7F;
    font-weight: 400;
    border-bottom: solid 1px #A8A8A8;
    display: flex;
    align-items: center;
    padding: 3% 7%;
}

.sort-nav-title img {
    margin-inline-end: 3%;
}

.sort-nav .sort-nav-option {
    cursor: pointer;
    padding: 3% 7%;
}

.sort-nav .sort-nav-option img.connect-icon {    
    width: 24px;
    height: 24px;
    padding: 0;
}

.sort-nav .sort-nav-option .battery-icon {
    margin-inline-start: 5px;
}

.sort-nav .sort-nav-option:hover {
    background-color: #EFF3F8;
}

.sort-nav .sort-nav-icon {
    padding-inline-end: 10%;
}
