.countries-row p{
    flex-basis: 25%;
    margin-bottom: 0%;
}


.wrap-watches-page{

    .watch-data-table{
        max-height: 500px;
        overflow: overlay;
    }
}

.wrap-messages-page{
    .messages-form{
        input[type="checkbox"] {
            vertical-align: middle;
            display: inline-flex;
            width: 20px;
            margin: 0 1%;
        }
    
    }
}