.profile-actions{
    display: flex;
}

.edit-profile-title {
    color: #053F7F;
    padding: 3% 0 0 3.2%;
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0;
}

.personal-fields{
    width: 100%;
    column-count: 2;
    column-gap: 50px;
    margin: 1% 2%;

    .personal-info-row{
        width: 90%;
        min-height: 55px;

        .col{
            display: flex;
            align-items: center;
        }

        .edit-profile-input{
            border: none;
            color: #3873AE;
            font-weight: 500;
            font-size: 1.1rem;
            background-color: transparent;
        }
    }
    .personal-info-row.active{
        background-color: #F2F6FA;
        color: #053F7F;
        font-weight: 500;
    }
    
    .personal-info-row.column-break, .personal-info-row:first-child {
        border-top:2px solid #DCDCDC;
    }

}

.edit-profile-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    padding: 1%;


    .btn-save{
        color: white;
        padding: 5px 20px;
        background-color: #3873AE;
        margin-right: 1.5%
    }

    p {
        padding: 2% 1.5% 0;
    }
    .edit-actions-divider{
        padding-top: 2%;
        color: #5EB6E8;
    }
}

