.pointer{
    cursor: pointer;
}
.registration-page{
    /* height: 100vh; */
}
.size-3{
    font-size: 1.5rem
}

.size-2{
    font-size: 1.2rem
}

.main-dark-blue{
    color: #053F7F
}

.wrap-info{
    justify-content: space-between;
}

.image-info{
    height: 40%;
}


/* pending page */
.pending-section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 40vmax;
    width: 95%;
    margin: 2% auto 0;
}
.pending-title{
    font-weight: 400;
    margin-bottom: 10%;
    color:#053F7F;
    text-align: center;
    margin-right: 100px;
}
.pending-section img{
    width: 80%;
}

/* uinqid page*/
.unique-section{
    display: flex;
    height: 100%;
    min-height: 42vmax;
    width: 95%;
    margin: 2% auto 0;
}

.unique-title{
    color:#053F7F;
    margin-top: 1%;
    margin-left: 3%;
    font-size: 20px;
}

.unique-discreption{
    color: black;
    margin-top: 1%;
    margin-left: 3%;
    opacity: 0.8;
}

.generate-img{
    width: 25px;
    height: 25px;
}

.unique-line{
    width: 28%;
    height: 1.5px;
    background-color:  black;
    margin-top: 0.5%;
    margin-left: 3%;
    opacity: 0.3;
}

.unique-id-error{
  color: red;
  margin-left: 50%;
  width: 200px;
}

.unique-id-valid{
    margin-left: 50%;
    width: 200px;
    color: green;
}
.unique-user-section{
    display: flex;
    flex-direction: row;
    margin-left: 3%;
    margin-top: 2%;
}

.unique-input{
    border-radius: 8px;
    margin-left: 50%;
    border: 2px solid gray;
    padding: 5px;
    width: 200px;
  
}

.unique-user-id{
    color: black;
    font-size: 22px; ;
}

.code-requirement{
    display: flex;
    flex-direction: column;
}
.generate-code-container{
    margin-left: 18%;
    margin-top: 2%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}
.generate-code{
    padding-left: 1%;
    cursor: pointer;
    color: #053F7F;
}

.characters-only{
    color: black;
    padding-top: 1%;
    margin-left: 18.3%;
    font-size: 12px;
}

.letters-numbers{
    color: black;
    margin-left: 18.3%;
    font-size: 12px;
}

.btn-submit{
    width: 80px;
    height: 35px;
    background-color: #053F7F;
    color: #fff;
    border-radius: 17px;
    margin-top: 50%;
    padding-left: 15px;
    padding-top: 6px;
    cursor: pointer;
    
}
.btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #053F7F;
    color: #fff;
}


/* denied page */
.denied-section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 42vmax;
    width: 95%;
    margin: 1% auto 0;
}
.denied-title{
    margin-top: -5%;
    font-size: 1.7rem;
    font-weight: 400;
    color:#053F7F;
    text-align: center;
    margin-right: 150px;
}
.denied-section img{
    width: 80%;
}

.register-link{
    margin-right: 100px;
    font-size: 1.5rem;
    font-weight: 400;
    color: #09ABFA
}

/* register personal info */
form.personal-info-form {
    column-count: 2;
    column-gap: 10%;
}

.personal-info-form .form-group.row.type{
    break-after: always;
    -moz-column-break-after: always;
    -webkit-column-break-after: always;
}

.personal-info-form .form-group.row {
    align-items: baseline;
}

form.personal-info-form label {
    color: black;
    font-size: 1.3rem;
    font-weight: 200;
    font-family: roboto;
}

.register-info .wrap-page-title {
    padding: 0;
    margin: 0;
    margin-bottom: 3%;
}

.register-info .page-title {
    padding-bottom: 2%;
    font-size: 1.3rem;
}